import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import './App.css';
import { CharacterSelector } from './lib/components/CharacterSelector';
import { Header } from './lib/components/Header';

const client = new ApolloClient({
  uri: 'https://graphql.anilist.co',
  cache: new InMemoryCache({
    typePolicies: {
      Page: {
        keyFields: [],
      },
    },
  }),
  fetchOptions: {
    mode: 'no-cors',
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Header />
        <div className="app-body">
          <CharacterSelector />
        </div>
      </div>
    </ApolloProvider>
  );
};

export default App;
