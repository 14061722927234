import React from 'react';
import { Character } from '../data/datatypes';

export const dataToLabel = ({ image, name, media }) => {
  const { english, romaji } = media.nodes[0].title;
  const title = english ? english : romaji;

  return (
    <div className='label-container'>
      <img alt='' className='circular-image' src={image.medium} />
      <div className='label'>
        <div className='label-text'>{name.full}     <sub>{title}</sub></div>
      </div>
    </div>
  )
};

export const dataToFullName = ({ name: { full } }) => full;

export const dataToCharacter = ({ id, favourites, name, image, media }) => new Character(id, favourites, name, image, media);

export const dataToCharacters = (data) => {
  if (data) {
    const { Page: { characters } } = data;
    return characters.map(dataToCharacter);
  }
}