import React from 'react';
import { Table } from 'react-bootstrap';

const capitalize = (key) => key.charAt(0).toUpperCase() + key.slice(1);

const Row = ([key, value]) => (
  <tr key={key}>
    <td>{capitalize(key)}</td>
    <td>{value}</td>
  </tr>
);

// Flat JSON -> Table
export const StripedAndBorderedTable = (object) => {
  const Rows = Object.entries(object).map(Row);

  return (
    <Table striped bordered>
      <tbody>
        {Rows}
      </tbody>
    </Table>
  );
};
