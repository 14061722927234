import React from "react";

const progressFill = ({ percent, color, float }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  float: float ? float : 'none',
  height: '100%',
  transition: 'width 1s ease-in-out',
  width: `${percent}%`,
  backgroundColor: color,
  textAlign: 'center'
});

const percent = (value, total) => (value / total) * 100;

const roundedPercent = (percent) => Math.round(percent);

const roundedPercentString = (percent) => `${roundedPercent(percent)}%`;


export const ComparisonBar = ({ leftValue = 1, rightValue = 1, leftColor = 'rgb(143, 176, 220)', rightColor = 'rgb(243, 175, 217)' }) => {
  const total = leftValue + rightValue;
  const leftPercent = percent(leftValue, total)
  const rightPercent = percent(rightValue, total)

  const containerStyles = {
    display: 'flex',
    height: '30px',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    overflow: 'hidden',
  }

  const progressLeftFill = progressFill({
    percent: leftPercent,
    color: leftColor
  });

  const progressRightFill = progressFill({
    percent: rightPercent,
    color: rightColor,
    float: 'right'
  });

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  }

  return (
    <div className='progress-bar-container'>
      <div style={containerStyles}>
        <div style={progressLeftFill}>
          <span style={labelStyles}>
            {roundedPercentString(leftPercent)}
          </span>
        </div>
        <div style={progressRightFill}>
          <span style={labelStyles}>
            {roundedPercentString(rightPercent)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ComparisonBar;
