/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { searchCharacters } from '../../io/anilist';
import { ComparisonBar } from '../ComparisonBar';
import { CharacterDetails } from './components/CharacterDetails';
import {
  DEFAULT_CHARACTER_1,
  DEFAULT_CHARACTER_2,
  NO_SELECTION_MESSAGE,
} from './data/defaults';
import './index.css';
import { dataToCharacters, dataToFullName } from './transformations';

const DetailDisplay = ({ image, name, favourites, media }) => (
  <CharacterDetails
    image={image}
    name={name}
    favourites={favourites}
    media={media}
  />
);

export const CharacterSelector = () => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(DEFAULT_CHARACTER_1);
  const [results, setResults] = useState({});

  const [name2, setName2] = useState('');
  const [loading2, setLoading2] = useState(false);
  const [selected2, setSelected2] = useState(DEFAULT_CHARACTER_2);
  const [results2, setResults2] = useState({});

  const client = useApolloClient();

  const runQuery = async (name, resultSet, loadingSet) => {
    loadingSet(true);
    const { data, error } = await client.query({
      query: searchCharacters({ name }),
    });
    if (error) {
      resultSet([]);
    } else {
      resultSet(dataToCharacters(data));
    }
    loadingSet(false);
  };

  useEffect(() => {
    try {
      if (name.length % 4 === 0) runQuery(name, setResults, setLoading);
    } catch (err) {
      console.log(err);
    }
  }, [name]);

  useEffect(() => {
    try {
      if (name2.length % 4 === 0) runQuery(name2, setResults2, setLoading2);
    } catch (err) {
      console.log(err);
    }
  }, [name2]);

  const loadOptions = (results) => (inputValue, callback) => callback(results);

  const onChange = (nameSet, selectedSet) => (data) => {
    nameSet(dataToFullName(data));
    selectedSet(data);
  };

  const onInputChange = (nameSet) => (input) => nameSet(input);

  return (
    <div className="fill-parent">
      <div className="character-select-container">
        {/* Select 1 */}
        <div className="characterSelectorManager">
          <div className="characterSelectorTextInput">
            <AsyncSelect
              placeholder={NO_SELECTION_MESSAGE}
              cacheOptions
              isLoading={loading}
              onInputChange={onInputChange(setName)}
              onChange={onChange(setName, setSelected)}
              loadOptions={loadOptions(results)}
            />
          </div>
          <div className="detail-display-container">
            <DetailDisplay
              name={selected.name}
              image={selected.image}
              favourites={selected.favourites}
              media={selected.media}
            />
          </div>
        </div>
        {/* Select 2 */}
        <div className="characterSelectorManager">
          <div className="characterSelectorTextInput">
            <AsyncSelect
              placeholder={NO_SELECTION_MESSAGE}
              cacheOptions
              isLoading={loading2}
              onInputChange={onInputChange(setName2)}
              onChange={onChange(setName2, setSelected2)}
              loadOptions={loadOptions(results2)}
            />
          </div>
          <div className="detail-display-container">
            <DetailDisplay
              name={selected2.name}
              image={selected2.image}
              favourites={selected2.favourites}
              media={selected2.media}
            />
          </div>
        </div>
        {/* Favorites Bar */}
      </div>
      <div className="favorites-bar-container">
        <ComparisonBar
          leftValue={selected.favourites}
          rightValue={selected2.favourites}
        />
      </div>
    </div>
  );
};
