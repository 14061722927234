import { dataToLabel } from '../../transformations';

export class Character {
  constructor(id, favourites, name, image, media) {
    this.id = id;
    this.value = name.full;
    this.label = dataToLabel({ image, name, media });
    this.name = name;
    this.image = image;
    this.favourites = favourites;
    this.media = media;
  }
};
