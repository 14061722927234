import { gql } from '@apollo/client';

export const searchCharacters = ({ name }) => {
  const search = name ? `search:"${name}"` : '';

  return gql`
  { 
    Page {
      characters(${search} sort:FAVOURITES_DESC) {
        id,
        favourites,
        image {
          large
          medium
        },
        name {
          first
          last
          full
          native
        },
        media {
          nodes {
            title {
              romaji
              english
              native
              userPreferred
            }
          }
        }
      }
    }
  }`
};
