
import React from 'react';
import { StripedAndBorderedTable } from '../../../Table';
import './index.css';

const CharacterImage = ({ image: { medium, large } }) => large ? <img alt='' className='image' src={large} /> : <img alt='' className='image' src={medium} />;

const CharacterTable = ({ name: { first, last, full, native }, favourites, media: { nodes } }) => {
  const DetailTable = StripedAndBorderedTable({ native, full, favourites });

  return (
    <div className='character-info-table'>
      {DetailTable}
    </div>
  )
};


export const CharacterDetails = ({ name, image, favourites, media }) => (
  <div className='character-detail-container'>
    <div className='character-image-container'>
      <CharacterImage image={image} />
    </div>
    <div className='character-info-container'>
      <CharacterTable name={name} favourites={favourites} media={media} />
    </div>
  </div>
);
