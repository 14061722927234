import DEFAULT_IMAGE_1_PNG from '../../../images/default.png';
import DEFAULT_IMAGE_2_PNG from '../../../images/default2.png';

export const NO_SELECTION_MESSAGE = 'Begin typing a characters name...';

const DEFAULT_IMAGE_1 = {
  medium: DEFAULT_IMAGE_1_PNG,
  large: DEFAULT_IMAGE_1_PNG
}

const DEFAULT_IMAGE_2 = {
  medium: DEFAULT_IMAGE_2_PNG,
  large: DEFAULT_IMAGE_2_PNG
}

const DEFAULT_NAME = {
  full: '?',
  first: '?',
  last: '?',
  native: '?'
}

const DEFAULT_MEDIA = {
  nodes: [
    {
      title: {
        romaji: '?',
        english: '?',
        native: '?',
        userPreferred: '?'
      }
    }
  ]
}

const DEFAULT_FAVOURITES = 1;

export const DEFAULT_CHARACTER_1 = {
  name: DEFAULT_NAME,
  image: DEFAULT_IMAGE_1,
  media: DEFAULT_MEDIA,
  favourites: DEFAULT_FAVOURITES
}

export const DEFAULT_CHARACTER_2 = {
  name: DEFAULT_NAME,
  image: DEFAULT_IMAGE_2,
  media: DEFAULT_MEDIA,
  favourites: DEFAULT_FAVOURITES
}