import React from 'react';
import { Navbar, Nav, NavLink, NavbarBrand } from 'react-bootstrap';
import API_LOGO from '../../images/anilistLogo.png';
import './index.css';

const MAIN_BRAND = 'ベストガール !';
const SUB_BRAND = 'BEST GIRL (⌒▽⌒)☆';
const API_CREDIT_TEXT = 'Powered by ';
const API_CREDIT_LINK = 'https://anilist.co/';

export const Header = () => (
  <Navbar bg='dark'>
    <Nav className="mr-auto">
      <NavbarBrand>
        <div className='nav-main-brand'>
          {MAIN_BRAND}
        </div>
      </NavbarBrand>
      <NavbarBrand>
        <div className='nav-sub-brand'>
          <sub>
            {SUB_BRAND}
          </sub>
        </div>
      </NavbarBrand>
    </Nav>
    <Nav className="ml-auto">
      <NavLink href={API_CREDIT_LINK} target='_blank'>
        <div className='nav-logo-brand'>
          {API_CREDIT_TEXT}
          <img alt='' className='header-logo' src={API_LOGO} />
        </div>
      </NavLink>
    </Nav>
  </Navbar>
);
